import React, { Component, ReactElement } from "react"

interface Props {
  text: string
  type: string
  icon?: ReactElement
  size?: string
  disable?: boolean
  className?: string
  onClick?: (e: any) => void
}

export default class Button extends Component<Props> {
  render() {
    let defaultSize = "px-5 py-3"
    if (this.props.size === "small") {
      defaultSize = "px-3 py-2"
    }

    let defaultEnable = "border"
    if (this.props.disable) {
      defaultEnable = "opacity-50 cursor-not-allowed"
    }

    let defaultClassName = `block rounded-md ${defaultEnable} border-transparent ${defaultSize} text-base font-medium shadow focus:outline-none focus:ring-2 focus:ring-offset-2 sm:px-10`

    if (this.props.className) {
      defaultClassName += ` ${this.props.className}`
    }

    if (this.props.type) {
      switch (this.props.type) {
        case "normal":
          defaultClassName +=
            " bg-arusgreen-500 text-white hover:bg-arusgreen-600 focus:ring-arusgreen-600"
          break
        case "dark":
          defaultClassName +=
            " bg-arusgreen-800 text-white hover:bg-arusgreen-900 focus:ring-offset-white"
          break
        case "light":
          defaultClassName +=
            " bg-white text-arusgreen-600 hover:bg-gray-100 focus:ring-green-700"
          break
        case "google":
          defaultClassName +=
            " bg-white border-2 border-gray-200 text-gray-700 hover:bg-gray-100 focus:ring-arusgreen-600"
          break
      }
    }

    return (
      <button
        type="button"
        className={defaultClassName}
        onClick={!this.props.disable ? this.props.onClick : undefined}
      >
        <div className="flex flex-row justify-center items-center">
          {this.props.icon ?? null}
          {this.props.text}
        </div>
      </button>
    )
  }
}
